import React from 'react';
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
//import LI from "../UI/List/Item";
import {Link} from "react-router-dom";
import Charly from '../../assets/img/CharlyProdWhite1.png'

function Footer() {
    return (
        <div className="footer-area sp-bottom" style={{backgroundColor:'grey', marginTop:'5rem'}} >
            <div className="container" >
                <div className="row mtn-40">
                
                    <div className="col-lg-4 order-4 order-lg-0">
                        <div className="widget-item">
                            <div className="about-widget">
                                <Link to=""
                                // {`${process.env.PUBLIC_URL + "/"}`}
                                >
                                    <img src={Charly} alt="Logo"/>
                                </Link>

                                <Text>
                                    Prennez soins de vous et vennez nous rendre visite à moins que ça soit nous!
                                </Text>

                                <Text classes="copyright-txt">
                                    &copy;{new Date().getFullYear()} CharlyProd Ltd. All Rights Reserved.
                                </Text>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-8 col-lg-4 ml-auto">
                        <Widget title="Les Sites web">
                            <List classes="widget-list">
                               <Link to="www.jemexhibe.fr">Je M'Exhibe.fr</Link>
                                <br />
                                <Link to="marketplace"> Marketplace (En construction)</Link>
   <br />
                                <Link to="grosse-pute.fr">Grosse Pute.fr</Link>
                                   <br />
                                 {/* <Link to=
                                ""
                                //{`${process.env.PUBLIC_URL + "/"}`}
                                >Careers</Link>
                                */}
                            </List>
                        </Widget>
                    </div>
                    

                      {/*<div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Social Links">
                            <List classes="widget-list">
                               <Link to="https://facebook.com/hasthemes/" target={'_blank'}>Facebook</Link>
                                <Link to="https://twitter.com/hasthemes/" target={'_blank'}>Twitter</Link>
                                <Link to="https://dribbble.com/hastech/" target={'_blank'}>Dribbble</Link>
                                <Link to="https://www.instagram.com/" target={'_blank'}>Instagram</Link>
                            </List>
                        </Widget>
                    </div>  */}

                    <div className="col-md-4 col-lg-3">
                        <Widget title="Contact Us">
                            <address>
                                contact@charlyprod.fr <br/>
                                johncoffee@lavache.com <br/>
                                https://charlyprod.fr <br/>
                            </address>
                        </Widget>
                    </div> 
                </div>
            </div> 
        </div>
    );
}

export default Footer;