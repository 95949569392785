import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

// import './contact.css'


const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_dy7lh3n', 'template_6ghazzc', form.current, 'zPCcOTEJbeoBEaHxf')
        e.target.reset()
  };

  return (
    <section id="contact">
    <div className="sm-top">
        <div className="container">
            <div className="row">
                <div className="col-12  text-center">
                    <h5>Ne soyez pas timide,<br/> n'hésiter pas à nous contacter!</h5>
                    <h2>Contactez nous et discutons de votre projet</h2>
                </div>
            </div>

            {/* END OF CONTACT OPTIONS */}
             <div className="row">
                <div className="col-12  text-center">
                    <form ref={form} onSubmit={sendEmail}>
                        <label className="col-12  text-center">
                            <input type="text" name='name' placeholder="Votre nom ou pseudo"  required />
                            <input type="email" name='email' placeholder="Votre Email"  required />
                        </label>
                        <br/>
                        <textarea style={{marginTop:"2rem"}} name="message" rows="8" placeholder="Votre message" required>
                        </textarea>
                        <button type="submit" className="btn btn-primary">Envoyer</button>
                    </form>
                </div>
            </div>
        </div>


    </div>
    </section>
  )
}

export default Contact