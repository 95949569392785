import React, {Component} from 'react';
import Charly from '../../assets/img/CharlyProdWhite1.png'


class Logo extends Component {
    render() {
        return (
            <div className="logo-area">
                <a href="/">   
                    <img src={Charly} alt="Charly Prod-Logo" />
                
                </a>
            </div>
        );
    }
}

export default Logo;