import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {Header, 
Slider, 
About, 
Features,
Services,
//Testimonials,
Team,
Blog,
BrandLogo,
Contact,
Footer
} from './components';
//import BlogPost from './pages/BlogDetails'

//import Header from './components/Header'
// import Slider from './components/Slider/home-one'
// import About from './components/About/home-one'
// import Features from './components/Features'
// import Services from './components/Services'
// import Team from "./components/Team/home-one";
// import Blog from "./components/Blog";
// import BrandLogo from "./components/BrandLogo";
// import Funfact from "./components/Funfact";
// import CallToAction from "./components/CallToAction";
//import Footer from "./components/index";
// import Testimonials from "./components/Testimonials/home-one";
// import LoginRegister from "./components/LoginRegister";
// import MobileMenu from "./components/MobileMenu";

const App = () => {
    return (
    <Router>
            <Header/>
            <Slider/>
            <About/>
            <Features classes="sp-top"/>
            <Services classes="sm-top-wt"/>
            <Team/>
            <Blog/>
            <BrandLogo/>
            <Contact />
            {/*
            <Testimonials/>
            <Funfact/>

            <CallToAction/>
            <MobileMenu/>
            <LoginRegister/>  
                       */}
            <Footer/>
        <Switch>
            {/* <Route exact path='/blog/:title' component={BlogPost} /> */}
        </Switch>
    </Router>
    );
};

export default App;